interface RouterConf {
    title: string     // 标题
    key: string
    brief?: string     // 简称
    icon?: any
    disabled?: boolean,
    isLeaf?: boolean
    children?: RouterConf[]
}

// const MenuList: RouterConf[] = [
//     {
//         title: '首页',
//         key: '/index',
//         icon: require('@/assets/menu/icon-1.png'),
//         isLeaf: true
//     },
//     {
//         title: '教学基础管理',
//         key: '/education',
//         icon: require('@/assets/menu/icon-2.png'),
//         children: [
//             {
//                 title: '年份管理',
//                 key: '/education/year',
//                 isLeaf: true
//             },
//             {
//                 title: '学科管理',
//                 key: '/education/subject',
//                 isLeaf: true
//             },
//             {
//                 title: '班型管理',
//                 key: '/education/class-type',
//                 isLeaf: true
//             },
//             {
//                 title: '年级管理',
//                 key: '/education/grade',
//                 isLeaf: true
//             },
//             {
//                 title: '学期管理',
//                 key: '/education/semester',
//                 isLeaf: true
//             },
//             {
//                 title: '教材版本',
//                 key: '/education/version',
//                 isLeaf: true
//             },
//             {
//                 title: '课程体系',
//                 key: '/education/course-system',
//                 isLeaf: true
//             },
//             {
//                 title: '章节体系',
//                 key: '/education/chapter-system',
//                 isLeaf: true
//             },
//             {
//                 title: '知识点体系',
//                 key: '/education/knowledge',
//                 isLeaf: true
//             },
//             {
//                 title: '知识元体系',
//                 key: '/education/knowledge-element',
//                 isLeaf: true
//             },
//             {
//                 title: '来源管理',
//                 key: '/education/source',
//                 isLeaf: true
//             },
//             {
//                 title: '题类管理',
//                 key: '/education/category',
//                 isLeaf: true
//             },
//             {
//                 title: '章节知识点关联',
//                 key: '/education/chapter-link-knowledge',
//                 isLeaf: true
//             },
//         ]
//     },
//     {
//         title: '员工管理',
//         key: '/customer',
//         icon: require('@/assets/menu/icon-3.png'),
//         children: [
//             {
//                 title: '校区管理',
//                 key: '/customer/campus',
//                 isLeaf: true
//             },
//             {
//                 title: '教师管理',
//                 key: '/customer/teacher',
//                 isLeaf: true
//             },
//             {
//                 title: '角色管理',
//                 key: '/customer/role',
//                 isLeaf: true
//             },
//             {
//                 title: '学生管理',
//                 key: '/customer/student',
//                 isLeaf: true
//             },
//             {
//                 title: '班级管理',
//                 key: '/customer/classes',
//                 isLeaf: true
//             },
//         ]
//     },
//     {
//         title: '模板管理',
//         key: '/mould',
//         icon: require('@/assets/menu/icon-4.png'),
//         children: [
//             {
//                 title: '知识元模板',
//                 key: '/mould/element-mould',
//                 isLeaf: true
//             },
//             {
//                 title: '试卷模板',
//                 key: '/mould/paper',
//                 isLeaf: true
//             }
//         ]
//     },
//     {
//         title: '系统设置',
//         key: '/system',
//         icon: require('@/assets/menu/icon-5.png'),
//         children: [
//             {
//                 title: '题型设置',
//                 key: '/system/question-type',
//                 isLeaf: true
//             },
//             {
//                 title: '公立校管理',
//                 key: '/system/school',
//                 isLeaf: true
//             },
//             {
//                 title: '备课设置',
//                 key: '/system/course-setting',
//                 isLeaf: true
//             }
//         ]
//     }
// ]

const MenuList: RouterConf[] = [
    {
        title: '首页',
        key: '/index',
        icon: require('@/assets/menu/icon-1.png'),
        isLeaf: true
    },
    {
        title: '教学基础管理',
        key: '/education',
        icon: require('@/assets/menu/icon-2-5.png'),
        children: [
            {
                title: '年份管理',
                key: '/education/year',
                isLeaf: true
            },
            {
                title: '学科管理',
                key: '/education/subject',
                isLeaf: true
            },
            {
                title: '班型管理',
                key: '/education/class-type',
                isLeaf: true
            },
            {
                title: '年级管理',
                key: '/education/grade',
                isLeaf: true
            },
            {
                title: '学期管理',
                key: '/education/semester',
                isLeaf: true
            },
            {
                title: '教材版本',
                key: '/education/version',
                isLeaf: true
            },
            {
                title: '来源管理',
                key: '/education/source',
                isLeaf: true
            },
            {
                title: '题类管理',
                key: '/education/category',
                isLeaf: true
            },
        ]
    },
    {
        title: '教研体系',
        key: '/research',
        icon: require('@/assets/menu/icon-2-7.png'),
        children: [
            {
                title: '课程体系',
                key: '/research/course-system',
                isLeaf: true
            },
            {
                title: '章节体系',
                key: '/research/chapter-system',
                isLeaf: true
            },
            {
                title: '知识点体系',
                key: '/research/knowledge',
                isLeaf: true
            },
            {
                title: '知识元体系',
                key: '/research/knowledge-element',
                isLeaf: true
            },
            {
                title: '章节知识点关联',
                key: '/research/chapter-link-knowledge',
                isLeaf: true
            },
        ]
    },
    {
        title: '用户管理',
        key: '/customer',
        icon: require('@/assets/menu/icon-2-6.png'),
        children: [
            {
                title: '校区管理',
                key: '/customer/campus',
                isLeaf: true
            },
            {
                title: '角色管理',
                key: '/customer/role',
                isLeaf: true
            },
            {
                title: '教师管理',
                key: '/customer/teacher',
                isLeaf: true
            },
        ]
    },
    {
        title: '教务管理',
        key: '/manage',
        icon: require('@/assets/menu/icon-2-8.png'),
        children: [
            {
                title: '班级管理',
                key: '/manage/classes',
                isLeaf: true
            },
            {
                title: '学生管理',
                key: '/manage/student',
                isLeaf: true
            },
        ]
    },
    {
        title: '模板管理',
        key: '/mould',
        icon: require('@/assets/menu/icon-4.png'),
        children: [
            {
                title: '知识元模板',
                key: '/mould/element-mould',
                isLeaf: true
            },
            {
                title: '试卷模板',
                key: '/mould/paper',
                isLeaf: true
            }
        ]
    },
    {
        title: '系统设置',
        key: '/system',
        icon: require('@/assets/menu/icon-5.png'),
        children: [
            {
                title: '题型设置',
                key: '/system/question-type',
                isLeaf: true
            },
            {
                title: '公立校管理',
                key: '/system/school',
                isLeaf: true
            },
            {
                title: '备课设置',
                key: '/system/course-setting',
                isLeaf: true
            },
            {
                title: '图标管理',
                key: '/system/icon-manage',
                isLeaf: true
            },
        ]
    }
]

export default MenuList;
