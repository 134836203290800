
























import { Component, Vue } from 'vue-property-decorator';
import MenuList from '@/core/menu-list';
import menuLogo from './components/menu-logo'

@Component({
    name: 'lay-menu',
    components: { menuLogo }
})
export default class extends Vue {
    private routerList:any = localStorage.getItem('axbp_roles');
    private list = JSON.parse(JSON.stringify(MenuList)).filter((m: any) => {
        m.closed = !this.$route.path.includes(m.key);
        if (this.routerList.includes(m.key) && m.children && m.children.length) {
            m.children = m.children.filter(j => this.routerList.includes(j.key));
        }
        return this.routerList.includes(m.key);
    });

    private systemName = process.env.VUE_APP_SYSTEM_NAME;

    private winScreen = document.body.offsetWidth > 1440 ? 45 : 36;

    created() {
        window.onresize = () => {
            this.winScreen = document.body.offsetWidth > 1440 ? 45 : 36;
        }
        /**
         * @ 随时随地更改菜单展示项
         */
        this.$bus.$on('USERROLE', (res) => {
            let routerList = res.menuUrls
            this.list = JSON.parse(JSON.stringify(MenuList)).filter((m: any) => {
                m.closed = !this.$route.path.includes(m.key);
                if (routerList.includes(m.key) && m.children && m.children.length) {
                    m.children = m.children.filter(j => routerList.includes(j.key));
                }
                return routerList.includes(m.key);
            })
        })
    }

    mounted() {
        if (this.list.length === 0) {
            localStorage.clear();
            this.$router.replace({path: `/error/404`})
        }
    }

    destroyed() {
        window.onresize = null;
    }

}
