const systemMap: Map<string, any> = new Map([
  ['jinbang', () => require('../../assets/menu/logo-jinbang.png')],
  ['xinzhou', () => require('../../assets/menu/logo-xinzhou.png')],
  ['sike', () => require('../../assets/menu/logo-sike.png')],
  ['shangzhi', () => require('../../assets/menu/logo-shangzhi.png')],
  ['xinbo', () => require('../../assets/menu/logo-xinbo.png')],
  ['feihong', () => require('../../assets/menu/logo-feihong.png')],
  ['tengda', () => require('../../assets/menu/logo-tengda.png')],
  ['default', () => require('../../assets/menu/logo.png')]
])
export default {
  functional: true,
  props: ['systemName'],
  render(h,{ props, data, children }) {
    return h('div', {
      class: {
        'logo-jinbang' : props.systemName === 'jinbang' || props.systemName === 'sike',
        'logo': props.systemName === 'default',
        'logo-xinzhou': props.systemName !== 'default' && props.systemName != 'undefined' && props.systemName !== 'jinbang' && props.systemName !== 'sike'
      }
    }, [
      h('img', {
        attrs: {
          src: systemMap.get(props.systemName)(),
          width: 150
        }
      })
    ])
  }
}
