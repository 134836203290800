
















import { Component, Watch, Vue, Provide } from 'vue-property-decorator';
import LayMenu from './menu.vue';
import LayHeader from './header.vue';
import RouteStore from '@/store/modules/route';
import Cookies from 'js-cookie'

@Component({
  components: { LayMenu, LayHeader },
  beforeRouteLeave(to: any, from: any, next: Function) {
    RouteStore.REMOVE_ROUTE_TAB(null);
    next();
  }
})
export default class Home extends Vue {

  private transitionName = '';

  private keepAlive: any[] = [];

  @Provide()
  userInfo = JSON.parse(localStorage.getItem('userInfo') as string)

  get routeTabs() { return RouteStore.routeTabs }

  @Watch('routeTabs')
  tabsChange() {
    this.keepAlive = this.routeTabs.map(e => e.name);
  }

  @Watch('$route')
  routeChange(to: any, from: any) {
    if (to.meta.keepAlive) {
      let isHas = this.routeTabs.some(n => n.name === to.name);
      if (!isHas) {
        RouteStore.SET_ROUTE_TAB(to);
      }
    }
    /* ------------- 转场动画 ------------- */
    if (to.meta.index > from.meta.index){
      this.transitionName = 'slide-left';
    } else{
      this.transitionName = 'slide-right';
    }
  }

  private isCollapse = false;
  created() {
    this.$bus.$on('nav-is-collapse', (e: boolean) => this.isCollapse = e);

    /* --------- 进入base页时，由于不触发路由变更，需在此添加需缓存路由 --------- */
    let isHas = this.routeTabs.some(r => r.path === this.$route.path);
    !isHas && RouteStore.SET_ROUTE_TAB(this.$route);
  }
  beforeDestroy() {
    this.$bus.$off('nav-is-collapse');
  }
}
