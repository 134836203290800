



































import { Component, Vue, Inject } from 'vue-property-decorator';
import Cookies from 'js-cookie'

@Component({
  name: 'lay-header'
})
export default class extends Vue {

  private size = this.$storage.get('size') || 'default';
  private sizeList = [{v: 'default', t: '默认'}, {v: 'medium', t: '中等'}, {v: 'small', t: '小型'}, {v: 'mini', t: '超小型'}]
  private defautlSizeChange(size: string) {
    this.$storage.set('size', size);
    location.reload();
  }


  private get breadcrumb() { return  this.$route.matched.reduce((t: string[], c) => {
      if (c.meta.title) { t.push(c.meta.title) }
      return t;
    }, [])
  }

  private logout() {
    this.axios.post('permission/auth/logout').then((res: any) => {
      localStorage.clear();
      this.$router.push('/login')
    })
  }

  private commandList = new Map([
    ['logout', () => this.logout()]
  ]);

  @Inject()
	userInfo?: Object

}
